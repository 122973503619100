import React from "react"

import Container from "../../../components/container/container"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

const getTimeOfDay = () => {
    const today = new Date();
    const curHr = today.getHours()

    if (curHr < 12) {
        return "Goedemorgen"
    } else if (curHr < 18) {
        return "Goedemiddag"
    } else {
        return "Goedenavond"
    }
}

const ContactLandingBlock = ({ darkBackground, backgroundColor }) => (
    <Section
        id="contact-landing"
        darkBackground={darkBackground}
        style={{ backgroundColor }}
    >
        <Container type="default">
            <div className="contact-landing-content">
                <TextBlock
                    title={getTimeOfDay() + ","}
                    subtitle="Hoe kunnen we u helpen?"
                    size="big"
                />  
            </div>
        </Container>
        <div className="contact-landing-background" />
    </Section>
)

export default ContactLandingBlock
