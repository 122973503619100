import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactBlock from "../blocks/general-blocks/contact-block/contact-block"

import ContactLandingBlock from "../blocks/contact-blocks/contact-landing-block/contact-landing-block"
import ContactWaysBlock from "../blocks/contact-blocks/contact-ways-block/contact-ways-block"


import { colors } from "../imports/colors"

const ContactPage = () => (
    <Layout footerDarkBackground={false}>
        <SEO
            title="Contact"
            description="Kom nu in contact met ons, waar wacht je nog op? Plan een afspraak in om de mogelijkheden te bespreken. info@digitam.nl"
            lang="nl"
            cannonicalUrl="https://digitam.nl/contact"
        />
        <ContactLandingBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <ContactWaysBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <ContactBlock darkBackground={false} backgroundColor={colors["grey-00"]} subtitle="Heeft u ons nodig?">
            Contact opnemen kan door een email te sturen naar <a href="mailto:info@digitam.nl" className="highlight-blue">info@digitam.nl</a> of door het formulier in te vullen.<br />
            Al klant bij ons? Dan kan je ons ook bellen.
        </ContactBlock>

    </Layout>
)

export default ContactPage
